.booking-home {
  &-main-content {
    padding-bottom: 5rem;
  }
}

.sculpt-pod-button,
.cryo-services-button,
.salon-services-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: black;
  border: 2px solid goldenrod;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 2rem;
  z-index: 5;
  position: relative;

  &-open {
    border: 2px solid transparent;
    background-image: linear-gradient(
      to bottom,
      #ae7822,
      #f4cf65,
      white,
      #f4cf65,
      #ae7822
    );
    * {
      color: black !important;
    }
  }
}

@-webkit-keyframes pull-down-pkg {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes pull-down-pkg {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.package-pull-down {
  -webkit-animation: pull-down-pkg 0.5s linear;
  animation: pull-down-pkg 0.5s linear;
  z-index: -10;
  display: flex;
  background-color: black;

  flex-direction: column;
}

@-webkit-keyframes push-up-pkg {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes push-up-pkg {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.package-push-up {
  -webkit-animation: push-up-pkg 0.5s linear;
  animation: push-up-pkg 0.5s linear;
  z-index: -10;
}

.black-spacer {
  width: 100vw;
  height: 1rem;
  background-color: black;
  position: relative;
  z-index: 1;
}

.service-module {
  margin: 1rem;
  border: 1px solid white;
  padding: 0.5rem;
  background-color: #2e2e2e;
  min-width: 300px;

  @media (min-width: 600px) {
    max-width: 50%;
  }

  &-wider {
    margin: 0rem;

    @media (min-width: 600px) {
      max-width: 75%;
    }
  }
}

.service-descriptor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service-module-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.book-now-button {
  padding: 1rem;
  font-size: 2rem;
  background-color: black;
  margin: 0.5rem 0rem;
  border: 2px solid goldenrod;
  cursor: pointer;

  &:hover {
    color: goldenrod;
    border: 2px solid white;
  }

  @media (max-width: 992px) {
    width: 95%;

    font-size: 1.75rem;
    padding: 0.5rem;
  }
}

#cryo-services-movable {
  position: relative;
  z-index: -11;
}

#salon-services-movable {
  position: relative;
  z-index: -12;
}

.booking-conditional {
  height: 78vh;
  padding: 0rem 1.5rem 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  &-buttons {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    * {
      width: 100%;
    }
  }
}

// .payment-option-buttons {
//   height: 78vh;
//   padding: 0rem 1.5rem 2rem 1.5rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-evenly;
//   align-items: center;

//   &-division {
//     height: 80%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     align-items: center;

//     * {
//       width: 100%;
//     }
//   }
// }

.account-creation-field {
  background-color: black;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid goldenrod;
  border-left: none;
  padding: 0.4rem;
  margin-top: 1.5rem;
  text-align: center;
  font-size: 1.2rem;
  min-width: 300px;
}
