@font-face {
  font-family: "Belleza";
  src: url("./fonts/Belleza-Regular.ttf");
}

* {
  font-family: "Belleza";
  color: white;
}

.grid-title {
  // color: orangered;

  text-shadow: 0px 0px 10px black;
  font-size: 2.8rem;

  @media (max-width: 430px) {
    font-size: 2.3rem;
  }
}

.gold-underline {
  cursor: pointer;
  font-size: 1.5rem;
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid goldenrod;
  }

  @media (max-width: 800px) {
    display: none;
  }
}

.gold-text {
  color: goldenrod;
}

.constant-underline {
  border-bottom: 2px solid goldenrod !important;
}

p {
  margin: 0px;
  padding: 0px;
}

.section-heading-text {
  font-size: 3rem;
}

.large-text {
  font-size: 3.5rem;
}

.medium-large-text {
  font-size: 2.5rem;
}

.medium-text {
  font-size: 2rem;

  @media (max-width: 630px) {
    font-size: 1.75rem;
  }
}

.small-medium-text {
  font-size: 1.75rem !important;
}

.small-text {
  font-size: 1.5rem;
}

.tiny-text {
  font-size: 1.25rem;
}

.center-text {
  text-align: center;
}

.max-text-width {
  max-width: 600px;
  width: 600px;

  @media (max-width: 600px) {
    max-width: 320px;
    width: 320px;
  }
}

.black-text {
  color: black;
}

.justify {
  text-align: justify;
}
