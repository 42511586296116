.homepage-logo {
  background-image: url("../images/dark-logo-web-edited.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 665px;
  width: 821px;

  @media (max-width: 1300px) {
    height: 499px;
    width: 616px;
  }

  @media (max-width: 900px) {
    height: 333px;
    width: 411px;
  }

  @media (max-width: 600px) {
    height: 243px;
    width: 300px;
  }
}

.homepage-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 5px;
  overflow: hidden;

  &:hover {
    text-shadow: 0px 0px 8px goldenrod;
  }
}

.shadows {
  background-image: url("../images/shadows-large.png");
  @media (max-width: 1220px) {
    background-image: url("../images/shadows-medium-large.png");
  }
  @media (max-width: 1000px) {
    background-image: url("../images/shadows-medium-small.png");
  }
  @media (max-width: 800px) {
    background-image: url("../images/shadows-small.png");
  }
  @media (max-width: 550px) {
    background-image: url("../images/shadows-tiny.png");
  }
}
