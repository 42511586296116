/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
    2. Remove default margin
  */
* {
  margin: 0;
  // overflow-x: hidden;
}
/*
    3. Allow percentage-based heights in the application
  */
html,
body,
#__next {
  height: 100%;
}
/*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
  */
* {
  line-height: calc(1em + 0.5rem);
}
body {
  //   line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}
/*
    6. Improve media defaults
  */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
    7. Remove built-in form typography styles
  */
input,
button,
textarea,
select {
  font: inherit;
}
/*
    8. Avoid text overflows
  */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
    9. Create a root stacking context
  */
#root,
#__next {
  isolation: isolate;
}

.full-viewport-height {
  height: 100vh;
}

// black global background
body {
  background-color: black;
}

@-webkit-keyframes slide-from-right {
  from {
    margin-left: 110vw;
  }
  to {
    margin-left: 0vw;
  }
}

@keyframes slide-from-right {
  from {
    margin-left: 110vw;
  }
  to {
    margin-left: 0vw;
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.slide-in {
  -webkit-animation: slide-from-right 0.4s linear 1;
  animation: slide-from-right 0.4s linear 1;
  min-height: 100vh;
  width: 100vw;

  &-nullify-height {
    min-height: unset;
  }
}

.full-height {
  height: 100%;
}

.no-scroll {
  overflow: hidden;
}

.hide-at-1300 {
  @media (max-width: 1300px) {
    display: none;
  }
}

.borderless {
  border: none !important;
  text-decoration: none !important;
}

.fade-in {
  -webkit-animation: fade-in 0.4s linear 1;
  animation: fade-in 0.4s linear 1;
}

.display-none {
  display: none;
}

.tiny-button {
  padding: 0.2rem;
  cursor: pointer;
  background-color: black;
  margin: 0;
  border: 2px solid goldenrod;

  &:hover {
    color: goldenrod;
    border: 2px solid white;
  }
}

.button-medium {
  padding: 0.4rem;
  font-size: 1.8rem;
  background-color: black;
  margin: 0;
  border: 2px solid goldenrod;
  cursor: pointer;

  &:hover {
    color: goldenrod;
    border: 2px solid white;
  }
}

.button-small {
  padding: 0.3rem;
  font-size: 1.4rem;
  background-color: black;
  margin: 0;
  border: 1px solid goldenrod;
  cursor: pointer;

  &:hover {
    color: goldenrod;
    border: 1px solid white;
  }
}

.test-checkbox {
  min-width: 50px;
  min-height: 50px;
  margin-right: 2.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.gold-border {
  border: 2px solid goldenrod !important;
}

.special-button {
  min-width: 100%;
}

.bayhawk-icon {
  height: 15px;
  width: 125px;
  background-image: url("./images/bayhawk-logo-dark.png");
  background-size: cover;
}

@-webkit-keyframes insta-gradient-animation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 91% 100%;
  }
}
@keyframes insta-gradient-animation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 91% 100%;
  }
}

@-webkit-keyframes insta-gradient-tablet-animation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 96% 100%;
  }
}
@keyframes insta-gradient-tablet-animation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 96% 100%;
  }
}

@-webkit-keyframes insta-gradient-mobile-animation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 100%;
  }
}
@keyframes insta-gradient-mobile-animation {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 100% 100%;
  }
}

.instagram-element {
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  height: 18vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: repeating-linear-gradient(
    217deg,
    #f9ce34,
    #ee2a7b,
    #6228d7,
    #ee2a7b,
    #f9ce34,
    #ee2a7b,
    #6228d7,
    #ee2a7b
  );

  background-size: 300% 100%;

  -webkit-animation: insta-gradient-animation 5s linear infinite;
  animation: insta-gradient-animation 5s linear infinite;

  @media (max-width: 1300px) {
    -webkit-animation: insta-gradient-tablet-animation 5s linear infinite;
    animation: insta-gradient-tablet-animation 5s linear infinite;
  }

  @media (max-width: 900px) {
    -webkit-animation: insta-gradient-mobile-animation 5s linear infinite;
    animation: insta-gradient-mobile-animation 5s linear infinite;
  }

  img {
    height: 12vh;
    width: 12vh;
  }

  p {
    font-size: 5rem;
    letter-spacing: 1rem;
    margin-left: 1rem;

    @media (max-width: 1200px) {
      font-size: 4rem;
      letter-spacing: 0.3rem;
    }
  }
}

.fixed-300-width {
  width: 300px;
}

.black-background {
  background-color: black;
}

.parent-width {
  width: 100%;
}
