.main-grid {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1300px) {
    height: unset;
  }

  @media (max-height: 850px) {
    height: unset;
  }

  &-row {
    height: 50%;
    width: 84%;
    max-width: 1550px;
    // background-color: green;
    display: flex;

    @media (max-height: 850px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width: 1920px) {
      width: 80%;
    }

    @media (max-width: 1750px) {
      width: 90%;
    }

    @media (max-width: 1550px) {
      width: 100%;
    }

    @media (max-width: 1300px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &-square {
    cursor: pointer;
    width: 33.33333%;
    min-width: 480px;
    min-height: 440px;
    display: flex;

    padding: 1rem;

    // border: 1px solid transparent;

    &:hover {
      // border: 1px solid #f4cf65;
      * {
        text-shadow: 0px 0px 5px goldenrod;
      }
    }

    @media (max-width: 1550px) {
      min-width: 440px;
      min-height: 440px;
    }

    @media (max-width: 1300px) {
      min-width: 600px;
      min-height: 600px;
    }

    @media (max-width: 630px) {
      min-width: 375px;
      min-height: 375px;
    }

    &-first {
      align-items: flex-end;
      background-image: url("../images/jake-1.png");
      background-size: cover;
    }

    &-second {
      text-shadow: 0px 0px 8px black;
      background-image: url("../images/cryoskin-2.png");
      background-size: cover;
    }

    &-third {
      text-shadow: 0px 0px 8px black;
      background-image: url("../images/sculptpod-1.png");
      background-size: cover;
    }

    &-fourth {
      text-shadow: 0px 0px 8px black;
      background-image: url("../images/hairsalon-1.png");
      background-size: cover;
    }

    &-fifth {
      text-shadow: 0px 0px 8px black;

      background-image: url("../images/boutique-1.png");
      background-size: cover;
    }

    &-sixth {
      text-shadow: 0px 0px 8px black;
      background-image: url("../images/joey.png");
      background-size: cover;
    }
  }
}

.grid-title {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &-cryoskin {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &-subtitle {
      display: flex;
      justify-content: center;
    }
  }

  &-sculpt-pod {
    align-items: center;
  }

  &-hair-salon {
    flex-direction: column;

    width: 30px;

    * {
      text-align: center;
    }
  }

  &-infusions {
    justify-content: space-between;
  }

  &-joey {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.contact-form-parent {
  width: 84vw;
  margin-left: 8vw;
  margin-right: 8vw;
  display: flex;
  justify-content: space-between;

  // background-color: purple;

  background-image: url("../images/contact-background.png");

  background-size: cover;

  @media (max-width: 1650px) {
    background-image: url("../images//contact-background-taller.png");
  }

  @media (max-width: 1500px) {
    background-image: none;

    justify-content: center;
  }
}

.contact-form {
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-right: 2rem;
  display: flex;
  padding: 2rem;

  background-color: black;

  flex-direction: column;
  align-items: center;
  width: 25%;
  min-width: 370px;

  font-size: 1.5rem;

  @media (max-width: 1500px) {
    margin-top: 0rem;
    padding-top: 0rem;
  }

  h2 {
    font-size: 2rem;
    border: none;
  }

  > * {
    margin-top: 1rem;
    background-color: black;
    border: 2px solid goldenrod;
    padding: 0.5rem;
    width: 100%;
  }
}

.button-main {
  padding: 1.5rem;
  font-size: 2.5rem;
  margin-top: 2rem;
  background-color: transparent;
  border: 2px solid goldenrod;
  width: 100%;
  max-width: 300px;
  cursor: pointer;

  @media (max-width: 1025px) {
    margin: 2rem 0rem;
    font-size: 1.8rem;
    padding: 1rem;
  }

  &:hover {
    color: goldenrod;
    border: 2px solid white;
  }

  &-disabled {
    opacity: 50%;
    pointer-events: none;
  }
}

textarea {
  min-height: 125px;
}

.main-menu {
  // background-color: orangered;
  overflow-x: hidden;
}

.large-top-margin {
  margin-top: 8rem;
}

.mobile-contact-girl {
  @media (min-width: 1500px) {
    display: none;
  }

  width: 100%;
  height: 1000px;

  background-image: url("../images/contact-background-taller.png");
  background-size: cover;

  @media (max-width: 700px) {
    height: 500px;
  }

  @media (max-width: 430px) {
    height: 400px;
  }
}
