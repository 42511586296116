.section-heading {
  align-items: center;
  border: 1px solid yellow;
  // background-color: pink;
  display: flex;
  height: 25vh;
  justify-content: space-evenly;
  max-height: 200px;
  width: 100%;
  margin-bottom: 1rem;

  &-image {
    max-height: 100%;
  }

  &-aidile {
    border: none;
    background-image: url("../images/aidile-cover.png");
    background-size: cover;
    color: black;

    @media (max-width: 1300px) {
      background-image: url("../images/aidile-cover-mid.png");
    }

    @media (max-width: 900px) {
      background-image: url("../images/aidile-cover-small.png");
      justify-content: flex-start;
      padding-left: 2rem;
    }

    @media (max-width: 550px) {
      background-image: url("../images/aidile-cover-tiny.png");
      font-size: 1.8rem;
    }
  }

  &-cryoskin {
    border: none;
    background-image: url("../images/cryoskin-cover.png");
    background-size: cover;
    color: white;
    text-shadow: 0px 0px 5px black;

    @media (max-width: 1300px) {
      background-image: url("../images/cryoskin-cover-mid.png");
    }

    @media (max-width: 900px) {
      background-image: url("../images/cryoskin-cover-small.png");
    }

    @media (max-width: 550px) {
      background-image: url("../images/cryoskin-cover-tiny.png");
      font-size: 1.8rem;
    }
  }

  &-about {
    border: none;
    background-image: url("../images/about-cover.png");
    background-size: cover;
    color: white;
    text-shadow: 0px 0px 5px black;

    @media (max-width: 1300px) {
      background-image: url("../images/about-cover-mid.png");
    }

    @media (max-width: 900px) {
      background-image: url("../images/about-cover-small.png");
    }

    @media (max-width: 550px) {
      background-image: url("../images/about-cover-tiny.png");
      font-size: 1.8rem;
    }
  }

  &-dashboard {
    border: none;
    background-image: url("../images/dashboard-cover.png");
    background-size: cover;
    color: white;
    text-shadow: 0px 0px 5px black;

    @media (max-width: 1300px) {
      background-image: url("../images/dashboard-cover-mid.png");
    }

    @media (max-width: 900px) {
      background-image: url("../images/dashboard-cover-small.png");
    }

    @media (max-width: 550px) {
      background-image: url("../images/dashboard-cover-tiny.png");
      font-size: 1.8rem;
    }
  }

  &-sculptpod {
    border: none;
    background-image: url("../images/pod-cover.png");
    background-size: cover;
    color: white;
    // text-shadow: 0px 0px 5px black;

    @media (max-width: 1300px) {
      background-image: url("../images/pod-cover-mid.png");
    }

    @media (max-width: 900px) {
      background-image: url("../images/pod-cover-small.png");
    }

    @media (max-width: 550px) {
      background-image: url("../images/pod-cover-tiny.png");
      font-size: 1.8rem;
    }
  }
}

.info-heading {
  margin: 0.5rem 0rem;
  font-size: 2.5rem;
}

.info-text {
  margin: 0rem 0rem 0.5rem 0rem;
  font-size: 1.8rem;
}

.black-ronaify-box {
  padding: 1rem;
  background-color: black;
}

.cryoskin-top {
  padding-bottom: 5rem;
}

.pod-benefits {
  font-size: 1.3rem;
}

.photo-paragraph {
  display: flex;
  margin-bottom: 5rem;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  &-2 {
    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }
  }

  * {
    width: 50%;

    @media (max-width: 992px) {
      width: 100%;
    }
  }

  &-text {
    padding-left: 2rem;
    margin-bottom: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    * {
      width: 100%;
    }
  }

  &-text-2 {
    padding-right: 2rem;
    margin-bottom: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    * {
      width: 100%;
    }
  }
}

.photo-with-text {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }

  &-salon {
    button {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width: 992px) {
        position: unset;
        transform: translateY(20px);
      }
    }
  }

  &-cryoskin {
    button {
      position: absolute;
      bottom: 20%;

      @media (max-width: 992px) {
        position: unset;
        transform: translateY(20px);
      }
    }
  }

  &-about {
    button {
      position: absolute;
      top: 50%;
      right: 50%;

      transform: translate(50%, -50%);

      @media (max-width: 992px) {
        position: unset;
        transform: translateY(20px);
      }
    }
  }
}

.infopage-button {
  padding: 1.5rem;
  font-size: 2.5rem;
  background-color: black;
  margin: 0;
  border: 2px solid goldenrod;

  &:hover {
    color: goldenrod;
    border: 2px solid white;
  }

  @media (max-width: 992px) {
    width: 95%;
    margin: 2rem 0rem;
    font-size: 1.8rem;
    padding: 1rem;
  }
}

.hairsalon-top {
  padding-bottom: 5rem;
}

.go-to-vagaro {
  text-align: center;

  &-button {
    padding: 1.5rem;
    font-size: 2.5rem;

    margin: 0rem 0.5rem 5rem 0.5rem;
    background-color: transparent;
    border: 2px solid goldenrod;

    @media (max-width: 992px) {
      width: 95%;
      margin: 2rem 0rem;
      font-size: 1.8rem;
      padding: 1rem;
    }

    &:hover {
      color: goldenrod;
      border: 2px solid white;
    }
  }
}

.sculptpod-section {
  display: flex;
  &-first {
    width: 75%;
  }

  &-second {
    width: 24.99999%;
    min-height: 270px;
  }
}

.sculptpod-photo {
  background-image: url("../images/sculptpod-2.png");
  background-size: cover;
}

.sculpt-pod-img {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 10px #830119;
}

.cryoskin-img {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 10px #2476ff;
}
