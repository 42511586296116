.popup {
  background-color: black;
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: flex-start;
  z-index: 2000;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &-body {
    height: 80vh;
    width: 35vw;
    background-color: black;
    padding: 0.75rem;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
      width: 50vw;
    }

    @media (max-width: 900px) {
      width: 70vw;
    }

    @media (max-width: 600px) {
      width: 90vw;
    }

    &-wider {
      @media (min-width: 1200px) {
        width: 50vw;
      }
    }
  }
  &-trans {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.custom-flex-column {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  * {
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
