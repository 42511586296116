.navbar {
  position: fixed;
  align-items: center;
  //   border: 1px solid yellow;
  display: flex;
  height: 18vh;
  justify-content: space-evenly;
  max-height: 200px;
  max-width: 100vw;
  width: 100%;
  background-color: black;
  z-index: 50;

  &-image {
    max-height: 100%;
    cursor: pointer;
  }
}

.stupid-element {
  height: 18vh;
  max-height: 200px;
  width: 100%;
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes raise-right-top-line {
  0% {
    rotate: 45deg;
    top: 15px;
    left: 0px;
  }

  100% {
    rotate: 0deg;
    top: 0px;
    left: 0px;
  }
}

@keyframes raise-right-top-line {
  0% {
    rotate: 45deg;
    top: 15px;
    left: 0px;
  }

  100% {
    rotate: 0deg;
    top: 0px;
    left: 0px;
  }
}

@-webkit-keyframes raise-right-bottom-line {
  0% {
    rotate: 0deg;
    bottom: 0px;
    left: 0px;
  }

  100% {
    rotate: -45deg;
    top: -15px;
    left: 0px;
  }
}

@keyframes raise-right-bottom-line {
  0% {
    rotate: 0deg;
    top: 0px;
    left: 0px;
  }

  100% {
    rotate: -45deg;
    top: -15px;
    left: 0px;
  }
}

@-webkit-keyframes drop-right {
  0% {
    rotate: 0deg;
    top: 0px;
    left: 0px;
  }

  100% {
    rotate: 45deg;
    top: 15px;
    left: 0px;
  }
}

@keyframes drop-right {
  0% {
    rotate: 0deg;
    top: 0px;
    left: 0px;
  }

  100% {
    rotate: 45deg;
    top: 18px;
    left: 0px;
  }
}

@-webkit-keyframes drop-right-bottom-line {
  0% {
    rotate: -45deg;
    top: -18px;
    left: 0px;
  }

  100% {
    rotate: 0deg;
    top: 0px;
    left: 0px;
  }
}

@keyframes drop-right-bottom-line {
  0% {
    rotate: -45deg;
    top: -18px;
    left: 0px;
  }

  100% {
    rotate: 0deg;
    top: 0px;
    left: 0px;
  }
}

.mobile-nav {
  height: 40px;
  width: 50px;
  // background-color: darkred;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &-d1,
  &-d2,
  &-d3 {
    width: 100%;
    height: 7px;
    background-color: white;
    border-radius: 10px;
  }

  @-webkit-keyframes show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &-animated-d2 {
    -webkit-animation: show 0.2s linear;
    animation: show 0.2s linear;
    background-color: white;
    width: 100%;
    height: 7px;
    background-color: white;
    border-radius: 10px;
  }

  &-animated-d1 {
    -webkit-animation: raise-right-top-line 0.2s linear;
    animation: raise-right-top-line 0.2s linear;
    background-color: white;
    width: 100%;
    height: 7px;
    background-color: white;
    border-radius: 10px;
    position: relative;
  }

  &-animated-d3 {
    -webkit-animation: drop-right-bottom-line 0.2s linear;
    animation: drop-right-bottom-line 0.2s linear;
    background-color: white;
    width: 100%;
    height: 7px;
    background-color: white;
    border-radius: 10px;
    position: relative;
  }
}

.mobile-nav-open {
  height: 40px;
  width: 50px;
  // background-color: darkred;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &-d1,
  &-d2,
  &-d3 {
    width: 100%;
    height: 7px;
    background-color: white;
    border-radius: 10px;
  }

  @-webkit-keyframes fade-to-transparent {
    0% {
      background-color: white;
    }

    100% {
      background-color: transparent;
    }
  }

  @keyframes fade-to-transparent {
    0% {
      background-color: white;
    }

    100% {
      background-color: transparent;
    }
  }

  &-d2 {
    -webkit-animation: fade-to-transparent 0.2s linear;
    animation: fade-to-transparent 0.2s linear;
    background-color: transparent;
  }

  &-d1 {
    position: relative;
    -webkit-animation: drop-right 0.2s linear;
    animation: drop-right 0.2s linear;
    rotate: 45deg;
    top: 18px;
    left: 0px;
  }

  &-d3 {
    position: relative;
    -webkit-animation: raise-right-bottom-line 0.2s linear;
    animation: raise-right-bottom-line 0.2s linear;
    rotate: -45deg;
    bottom: 15px;
    left: 0px;
  }
}

.dummy-nav {
  height: 40px;
  width: 50px;
  background-color: transparent;
}

// this must be executed AFTER the mobile-nav and dummy-nav blocks
.show-at-800 {
  display: none;
  @media (max-width: 800px) {
    display: flex;
  }
}

@-webkit-keyframes pull-down {
  0% {
    top: -101vh;
  }

  100% {
    top: 0px;
  }
}

@keyframes pull-down {
  0% {
    top: -101vh;
  }

  100% {
    top: 0px;
  }
}

@-webkit-keyframes push-up {
  0% {
    top: 0px;
  }

  100% {
    top: -101vh;
  }
}

@keyframes push-up {
  0% {
    top: 0px;
  }

  100% {
    top: -101vh;
  }
}

.mobile-nav-full {
  -webkit-animation: pull-down 0.5s linear;
  animation: pull-down 0.5s linear;
  background-color: black;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.mobile-nav-item {
  margin: 1rem;
  font-size: 2rem;
}
